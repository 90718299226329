import React from 'react'
import PropTypes from 'prop-types';
import {Route, Redirect} from 'react-router-dom'; 

export default function IsUserLoggedIn({user,loggedInPath, children, ...rest}) {
    return (
        <Route
            {...rest}
            render={({location}) => {
                if(!user){
                    return children;
                }

                if(user) {
                    return (
                        <Redirect
                            to={{
                                pathname: loggedInPath,
                                state: {from : location}
                            }}
                        />
                    )
                }
            }}
        />
    )
}

IsUserLoggedIn.propTypes = {
    user: PropTypes.object,
    children: PropTypes.object.isRequired,
    loggedInPath: PropTypes.string.isRequired    
}